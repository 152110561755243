@keyframes heartBeat {
    0% {
      transform: scale(0.75);
    }
    20% {
      transform: scale(1.1);
    }
    40% {
      transform: scale(0.75);
    }
    60% {
      transform: scale(1.1);
    }
    80% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(0.75);
    }
  }
  
  .attendance-animate-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .heart {
    width: 100px;
    height: 100px;
    background-color: red;
    position: relative;
    transform: rotate(-45deg);
    animation: heartBeat 1s ease-in-out infinite;
  }
  
  .heart:before,
  .heart:after {
    content: "";
    width: 100px;
    height: 100px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
  }
  
  .heart:before {
    top: -50px;
    left: 0;
  }
  
  .heart:after {
    left: 50px;
    top: 0;
  }
  
  .attendance-animate-text {
    font-size: 2rem;
    color: #ffffff;
    margin-top: 20px;
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  