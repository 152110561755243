.App {
  margin: auto;
  content: "";
  clear: both;
  display: table;
  width: 100%;
  max-width: 800px;
  min-width: 360px;
  height: 93vh;
  word-break: break-word;
  overflow-wrap: anywhere;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.img {
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.iframe { 
  margin: auto;
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.react-calendar {
  margin: auto;
  min-width: 90%;  
  border-radius: 10px;
}

.dot {
  margin: auto;
  height: 8px;
  width: 8px;
  background-color: limegreen;
  border-radius: 50%;
  display: flex;
}

.holiday {
  color: red;
}

.small-text {
  font-size: 0.7rem;
}
